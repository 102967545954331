import React from "react";
import "./Footer.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-container">
      {/* <section className="footer-subscription"> */}
      {/* <p className="footer-subscription-heading">
          Don't look for job forever! Get weekly premium job notifications
          before anyone else.
        </p> */}
      {/* <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p> */}
      {/* <div className="input-areas">
          <form>
            <input
              className="footer-input"
              name="email"
              type="email"
              placeholder="Your Email"
            />
            <Button buttonStyle="btn--outline">Subscribe</Button>
          </form>
        </div> */}
      {/* </section> */}
      <div class="footer-links">
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            {/* <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link> */}
            {/* <Link to='/'>Testimonials</Link> */}
            {/* <Link to='/'>Careers</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Service</Link> */}
          </div>
          <div class="footer-link-items">
            <h2>Contact Us</h2>
            {/* <Link to='/'>Contact</Link> */}
            Give us a call at +44 75012 74918
            <br />
            <br />
            Email us on chris@kingsentrepreneurs.com
            {/* <Link to='/'>Support</Link>
            <Link to='/'>Destinations</Link>
            <Link to='/'>Sponsorships</Link> */}
          </div>
          <div className="footer-link-wrapper">
            <div class="footer-link-items">
              {/* <h2>Videos</h2> */}
              {/* <Link to='/'>Submit Video</Link> */}
              {/* <Link to='/'>Ambassadors</Link>
            <Link to='/'>Agency</Link>
            <Link to='/'>Influencer</Link> */}
            </div>
            {/* <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Instagram</Link> */}
            {/* <Link to='/'>Facebook</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link> */}
            {/* </div> */}
          </div>
        </div>
      </div>
      <section class="social-media">
        <div class="social-media-wrap">
          <div class="footer-logo">
            <Link to="/" className="social-logo">
              internss
              <i class="fab fa-typo3" />
            </Link>
          </div>
          <small class="website-rights">internss © 2021</small>
          <div class="social-icons">
            {/* <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link> */}
            {/* <Link
              class="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="Twitter"
            >
              <i class="fab fa-twitter" />
            </Link> */}

            <a
              class="social-icon-link twitter"
              aria-label="LinkedIn"
              target="_blank"
              href="https://www.linkedin.com/company/internss-com"
            >
              <i class="fab fa-linkedin" />
            </a>

            {/* <Link
              class="social-icon-link twitter"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdWwVMSO7YXy2oWZyqxx1WLGbBxjrGyOw4FuSJNPApI3JqCUA/viewform?vc=0&c=0&w=1&flr=0"
              target="_blank"
              aria-label="LinkedIn"
            >
              <i class="fab fa-linkedin" />
            </Link> */}
            {/* <Link
              class="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <i class="fab fa-linkedin" />
            </Link> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
