import http from "../http-common";

class JobDataService {
  getAll(page, by, query) {
    // console.log(by, query);
    return http.get(`jobs?page=${page - 1}&${by}=${query}`);
  }
  
  // getAll(page) {
  //   return http.get(`jobs?page=${page - 1}`);
  // }

  getPage(totalPages) {
    return http.get(`jobs?pagenum=${totalPages}, data)`);
  }

  get(id) {
    return http.get(`/job?id=${id}`);
  }

  // find(query, by = "companyName", page, pages) {
  //   return http.get(`jobs?${by}=${query}=${page}=${pages}`);
  // }

  find(query, by = "companyName") {
    // console.log(by, query);
    return http.get(`jobs?${by}=${query}`);
  }
  retrieveFilteredJobs(query, by = "companyName", page) {
    return http.get(`jobs?${by}=${query}=${page}`);
  }

  getCuisines(id) {
    return http.get(`/cuisines`);
  }
}

export default new JobDataService();
