import React, { useState, useEffect } from "react";
import Pagination from "./Pagination";
import JobDataService from "./services/job";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import "./Cards.css";
import CardItem from "./CardItem";
import "./HeroSection.css";
import "./Button.css";
import Tesla from "./images/tesla_black_long.png";
import PayPal from "./images/paypal_black.png";
import Snap from "./images/snap_inc_text.png";
import Disney from "./images/Disney.png";
import Intel from "./images/Intel_black.png";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import "react-toggle/style.css";
import NewsletterForm from "./NewsletterForm";
import "./newButton.css";

const checkboxes = [
  {
    name: "🤵‍♀️ Finance",
    key: "financeKey",
    label: "financeLabel",
  },
  {
    name: "👩‍🎨 Marketing",
    key: "marketingKey",
    label: "marketingLabel",
  },
  {
    name: "👨‍💼 Sales",
    key: "salesKey",
    label: "salesLabel",
  },
  {
    name: "🥷 Operations",
    key: "operationsKey",
    label: "financeLabel",
  },
  {
    name: "👨‍💻 Software Engineering",
    key: "softwareEngineeringKey",
    label: "softwareEngineeringLabel",
  },
  {
    name: "🏙 United Kingdom",
    key: "unitedKingdomKey",
    label: "unitedKingdomLabel",
  },
  {
    name: " 🌎 Remote",
    key: "remoteKey",
    label: "remoteLabel",
  },
];
const Checkbox = ({
  type = "checkbox",
  name,
  checked = false,
  onChange,
  color,
}) => {
  return (
    <input type={type} name={name} checked={checked} onChange={onChange} />
  );
};

const JobsList = (props) => {
  const [click, setClick] = useState(true);
  const pageNumber = props.pageNumber || 1;
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(pageNumber);
  const [pages, setPages] = useState(1);
  // const [color, setColor] = useState('black')
  const url =
    "https://vvatch.us18.list-manage.com/subscribe/post?u=070d797cbb2c98691133de6d6&amp;id=71f6229e14";

  const [checkedItems, setCheckedItems] = useState(""); //plain object as state

  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });

    console.log(checkedItems);
    find(event.target.name);
  };

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        if (checkedItems) {
          retrieveJobsFiltered("companyName", Object.keys(checkedItems));
          pages = retrievePages();
          setJobs(jobs);
        } else {
          retrieveJobs();
          retrievePages();
          pages = retrievePages();
          setJobs(jobs);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchJobs();
    // console.log("checkedItems from UseEffect: ", checkedItems);
    // console.log("This is page: ", page);
    window.scrollTo(500, 500);
  }, [page, checkedItems]);

  const retrievePages = () => {
    JobDataService.getPage(pages)
      .then((response) => {
        setPages(response.data.totalPages);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const retrieveJobs = () => {
    JobDataService.getAll(page)
      .then((response) => {
        console.log(response.data);
        setJobs(response.data.jobs);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const retrieveJobsFiltered = (by = "companyName", query) => {
    JobDataService.getAll(page, by, query)
      .then((response) => {
        console.log(response.data);
        setPages(response.data.totalPages);
        setJobs(response.data.jobs);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const find = (query, by) => {
    JobDataService.find(query, by)
      .then((response) => {
        console.log(response.data);
        setJobs(response.data.jobs);
        setPages(response.data.totalPages);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div>
      <div>
        <div className="hero-container">
          <Link to="/hire" className="btn--post">
            Post a job
          </Link>
          <h1>
            The fastest way to find your next <br />{" "}
            <div className="headline-placeholder"></div>
          </h1>
          {/* <div> */}
          <p>Share your profile and let employers find you. 🚀</p>
          {/* </div> */}

          <div className="mainApplyButton-div">
            <a
              className="button--mainApply"
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdWwVMSO7YXy2oWZyqxx1WLGbBxjrGyOw4FuSJNPApI3JqCUA/viewform?vc=0&c=0&w=1&flr=0"
            >
              Apply now
            </a>
          </div>

          <div className="align-center">
            <div>
              <div className="employers-text">
                Connect with the world's best employers
              </div>
            </div>
            <div className="column">
              <div className="row">
                <div className="img-PayPal">
                  <img src={PayPal} />
                </div>
                <div className="img-Tesla">
                  <img src={Tesla} />
                </div>
                <div className="img-Intel">
                  <img src={Intel} />
                </div>
                <div className="img-Snap">
                  <img src={Snap} />
                </div>
                <div className="img-Disney">
                  <img src={Disney} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>

        <div>
          <div className="allButtons-div">
            <div className="buttons-div">
              <div className="filter-text">Filter by category 📌</div>

              <div>
                <label>{checkedItems[""]}</label>
                {checkboxes.map((item) => (
                  <label
                    // style={{background:color}}
                    // onClick={()=>{setColor("red")}}
                    className="btn--position"
                    key={item.key}
                  >
                    {item.name}
                    <Checkbox
                      name={item.name}
                      checked={checkedItems[item.name]}
                      onChange={handleChange}
                    />
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="jobs__item">
        {jobs.map((job) => {
          return (
            <div>
              <div>
                <div className="jobs__item__wrap">
                  <CardItem
                    src={job.companyImage}
                    companyName={job.companyName}
                    jobDescription={job.jobDescription}
                    jobPosition={job.jobPosition}
                    cuisine={job.cuisine}
                    location={job.location}
                    datePosted={job.datePosted}
                    applyLink={job.applyLink}
                    featured={job.featured}
                  />
                </div>
              </div>
              <div className="subscribe-email-container">
                <div className="subscribe-email-item-div">
                  <div className="subscribe-email-item">
                    <MailchimpSubscribe
                      url={url}
                      render={(props) => {
                        const { subscribe, status, message } = props || {};
                        return (
                          <NewsletterForm
                            status={status}
                            message={message}
                            onValidated={(formData) => subscribe(formData)}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <Pagination page={page} pages={pages} changePage={setPage} />
      </div>
    </div>
  );
};

export default JobsList;
